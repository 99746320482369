export type CompareType = 'equal' | 'contains' | 'between'

//TODO:WMLN-2623 довольно негибко. У разных таблиц может быть разная логика по типу сравнения.
export function getDefaultCompareTypeByColumn(id: string): CompareType {
  switch (id) {
    case 'invoiceDate':
    case 'purchaseDate':
    case 'deadlineDate':
    case 'dlOrder':
    case 'estimatedOrderDate':
      return 'between'
    case 'store':
    case 'isHazmat':
    case 'isTender':
    case 'sample':
    case 'express':
    case 'orderDetails':
    case 'orderId':
    case 'paymentStatus':
      return 'equal'
    default:
      return 'contains'
  }
}
