import { API_URL } from 'src/config/environment'
import apiCreator from '../api-creator'
import {EditItemAdditionalFields} from '../common/temp'

interface SaveItemRequestData extends EditItemAdditionalFields {
  id: string
  brand: string
  catalogue_id: string
  country: string
  deadline_date: string
  name: string
  procurement_comment: string
  original_name: string
  store: string
  procurement: string
  provider: string | null
  procurement_dl_reason: string | null
}

export function saveProcurementItem(data: SaveItemRequestData): Promise<void> {
  return apiCreator
    .post(
      `${API_URL.TWIN}/save-item`,
      data,
    )
}
