import { API_URL } from 'src/config/environment'
import {OrderStatusId} from '../../types/order-status'
import {PaymentMethodId} from '../../atoms/static-data/payments-method-atom'
import apiCreator from '../api-creator'

export interface CreateOrderRequestItemData {
  id: string
  brand: string
  sample: boolean
  esd_fixed: boolean
  catalogue_id: string
  original_name: string
  esd_fix_original_date: string | null
  name: string
  cost: number
  tax?: number
  other_cost?: number
  quantity: number
  discount_percent?: number
  vendor_price: string
  discount_absolute?: number
  discount_comment?: string
  esd_date: string | null
  esd_fix_date: string | undefined
  payment_deadline?: string | null
  supply_paid_date?: string | null
  invoice_date?: string | null
  registry_number?: string | null
  terms_of_payment?: string | null
  procurement_comment: string
  next_check: string | null
  invoice_price?: number
  is_kit: boolean
  tsw_name: string | null
  payment_type: string
  invoice_number: string
  finance_control_comment: string
  hs_code_china: string
  order_comment: string
  order_details: string
  procurement_dl_reason: string
  procurement_dl_comments: string
  eod: string | null
  prt_channel_change_reason: string
  is_shipped_in_advance: boolean
}

export interface CreateOrderRequestOrderFields {
  id?: string //Есть id-создание, иначе редактирование
  brand_contact?: string
  confirmed_date: string | null
  payment_deadline: string | null
  prepayment_amount: number
  country: string
  payment_details: string
  payment_percentage: string
  payment_type: string
  proc_trans_for_payment_date: string | null
  sum_vendor_price: number | null
  currency: string
  store_order_number: string
  payment_priority: string
  ordered_date?: string
  paid_date: string | null
  payment_method: PaymentMethodId
  po_number?: string
  provider: string
  responsible_procurement: string
  status: OrderStatusId
  store: string
  sum_other_cost_formula?: string
  sum_tax?: number
  tracking_number?: string
  card?: string
  tsw_name: string | null
  sum_cost_usd?: number
  docs: Array<string>
  order_comment: string
}

interface CreateOrderRequestData {
  order: CreateOrderRequestOrderFields
  items: Array<CreateOrderRequestItemData>
}

interface CreateOrderResponseData {
  data: {
    order_id: string
  }
}

export function saveProcurementOrder(
  data: CreateOrderRequestData,
): Promise<CreateOrderResponseData> {
  return apiCreator
    .post(
      `${API_URL.TWIN}/save-order`,
      data,
    )
}
